<template>
  <div>
    <div v-if="!userInfo.uid" class="not-login-box">
      <div class="not-login-content">
        <div class="left">
          <div class="tip">让更多人接触并学习传统知识</div>
          <div class="tip">让传统知识社区分享更多惠益</div>
          <el-button class="btn-register" @click="toRegisterPage">注册</el-button>
        </div>
        <img class="img-home-brand" src="@/page-home/assets/home.png" />
      </div>
    </div>
    <div class="page-wrapper"
         v-loading="loading" element-loading-text="数据加载中...">
      <!--<div class="up-cate">-->
      <!--  <h3>数据分类：</h3>-->
      <!--  <el-select v-model="selectCateId"-->
      <!--             placeholder="请选择数据分类" @change="handleCateChange">-->
      <!--    <el-option-->
      <!--            v-for="item in cates"-->
      <!--            :key="item.id"-->
      <!--            :label="item.name"-->
      <!--            :value="item.id">-->
      <!--    </el-option>-->
      <!--  </el-select>-->
      <!--</div>-->
      <!--<vue-waterfall-easy :imgsArr="arts" style="height: 1200px">-->
      <!--  &lt;!&ndash;<div class="img-info" slot-scope="props">&ndash;&gt;-->
      <!--  &lt;!&ndash;  <p class="some-info">picture index: {{props.index}}</p>&ndash;&gt;-->
      <!--  &lt;!&ndash;  <p class="some-info">{{props.value.info}}</p>&ndash;&gt;-->
      <!--  &lt;!&ndash;</div>&ndash;&gt;-->
      <!--  <div class="item-art-wrapper" slot-scope="item" @click="handleArtItemClick(item.artId)">-->
      <!--    <el-image class="img-cover" :src="item.coverImg" />-->
      <!--    <div class="cate-name">-->
      <!--      {{item.cateName}}-->
      <!--    </div>-->
      <!--    <div class="title">-->
      <!--      {{item.title}}-->
      <!--    </div>-->
      <!--    <div class="desc">-->
      <!--      {{item.description}}-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</vue-waterfall-easy>-->

      <div v-if="arts.length > 0" class="arts-wrapper">
        <div v-for="(item, index) in arts" :key="index"
             class="item-art-wrapper" @click="handleArtItemClick(item)">
          <el-image class="img-cover" :src="item.coverImg" />
          <div class="cate-name">
            {{item.cateName}}
          </div>
          <div class="title">
            {{item.title}}
          </div>
          <div class="desc ellipsis">
            {{item.description}}
          </div>
        </div>
        <i></i><i></i><i></i><i></i>
      </div>
      <div v-else class="no-content">
        <img class="img-no" src="@/page-home/assets/no-content.png" />
        <div class="tip">作品正在创作中</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getAllCategory, getArtsByPage} from "@/page-home/api/home";
  import {mapState} from "vuex";

  export default {
    name: "Home",
    data() {
      return {
        selectCateId: 0,
        loading: true,
        cates: [],
        arts: [],
        page: {
          curPage: 1,
          pageSize: 30
        },
        hasMore: true
      }
    },

    computed: {
      ...mapState({
        userInfo: state => state.userInfo,
        selCateId: state => state.selCateId
      }),

      infDisabled() {
        console.log(this.loading || !this.hasMore);
        // return this.loading || !this.hasMore
        return false
      }
    },

    watch: {
      selCateId() {
        this.loading = true
        this.arts = []
        this.getArtsFromApi().then(() => {
          this.loading = false
        })
      }
    },

    methods: {
      getAllCategoryFromApi() {
        getAllCategory().then(res => {
          this.cates = res.data || []
          this.cates.unshift({id: 0, name: '全部'})
        })
      },

      getArtsFromApi() {
        const payload = {
          cateId: this.selCateId,
          curPage: this.page.curPage,
          pageSize: this.page.pageSize
        }
        return getArtsByPage(payload).then(res => {
          // this.arts = res.data.records || []
          this.arts = this.arts.concat(res.data.records || [])
          if (res.data.pages === this.page.curPage) {
            this.hasMore = false
          } else {
            this.hasMore = true
          }
        })
      },

      loadMore() {
        console.log('load1');
        if (this.hasMore) {
          this.page.curPage++
          this.getArtsFromApi()
        } else return
      },

      handleCateChange(val) {
        this.arts = []
        this.loading = true
        this.getArtsFromApi().then(() => {
          this.loading = false
        })
      },

      handleArtItemClick(item) {
        let routeUrl = this.$router.resolve({
          path: '/art/detail',
          query: {id: item.artId, v: item.version}
        })
        window.open(routeUrl.href, '_blank');
        // this.$router.push('/art/detail?id=' + artId)
      },

      toRegisterPage() {
        this.$router.push('/register')
      },

      scrollFunc() {
        if (document.documentElement.scrollTop + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 400) {
          this.loadMore()
        }
      }
    },

    created() {
      this.loading = true
      Promise.all([this.getArtsFromApi()]).then(res => {
        this.loading = false
      })
    },

    mounted() {
      window.addEventListener('scroll', this.scrollFunc)
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollFunc)
    }
  }
</script>

<style lang="scss" scoped>
  .not-login-box {
    width: 100%;
    height: 300px;
    background-color: #F8F7FC;
    .not-login-content {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      .left {
        margin-right: 100px;
        .tip {
          font-size: 30px;
          font-weight: 500;
          color: #000000;
          line-height: 50px;
        }
      }
    }
  }
  .page-wrapper {
    height: 100%;
    padding: 20px;
    .up-cate {
      display: flex;
      align-items: center;
      margin-left: 40px;
    }
  }

  .arts-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    i {
      width: 300px;
      margin-right: 20px;
    }
  }
  .item-art-wrapper {
    position: relative;
    width: 300px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-radius: 10px 10px 2px 2px;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    .img-cover {
      width: 300px;
      height: 226px;
    }
    .title {
      margin: 12px 0;
      font-size: 18px;
      color: #000000;
      font-weight: 500;
    }
    .desc {
      font-size: 15px;
      color: #ABAAB1;
    }
    .cate-name {
      position: absolute;
      top: 14px;
      right: 10px;
      padding: 6px 10px;
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.2);
    }
    &:hover {
      box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    }
  }

  .no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    .tip {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #9D9D9E;
    }
  }

  .btn-register {
    width: 88px;
    height: 44px;
    margin-top: 40px;
    font-size: 18px;
    color: #ffffff;
    background-color: #004DF8;
    border-color: #004DF8;
  }

  .img-home-brand {
    width: 700px;
    height: 320px;
  }
</style>
